import { Profile } from './useProfiles';

const pixel_prestige_winners = ['andrew.matuza@'];
const hackathon_winners = [
  'ryan.mccloskey@',
  'hamish.graham@',
  'alexandru.nagy@',
  'georgios.efstathopoulos@',
  'katarina.lauritano@',
  'vasileia.lellou@',
  'thomas.kennedy@',
];
const talos_badges_qa_specialists = ['ralfi@', 'anton.deshchenko@', 'teresa.garcia@'];

/**
 * Keeps your original auto-claim and restricted logic.
 * Adjust or remove the "uwpa"/"65ry" checks as needed.
 */
export function getAutoUpdatedMap(
  enabledBadgesMap: Map<string, boolean>,
  badgesMap: Map<
    string,
    {
      id: string;
      autoClaimRules?: { ids: string[]; count: number };
      banSelfClaim?: boolean;
      restrictedRules?: { ids: string[]; count: number };
      name: string;
    }
  >,
  selectedProfile: Pick<Profile, 'submittedBadgeIDs' | 'email'>
): Map<string, boolean> {
  const newEnabledBadgesMap = new Map(enabledBadgesMap);
  const submittedBadgesCount = selectedProfile.submittedBadgeIDs.length;

  for (const badge of badgesMap.values()) {
    const { id, autoClaimRules, banSelfClaim, restrictedRules } = badge;

    // Enable if auto-claim conditions are satisfied
    if (
      autoClaimRules &&
      autoClaimRules.ids.filter((depId) => newEnabledBadgesMap.get(depId)).length >= autoClaimRules.count
    ) {
      newEnabledBadgesMap.set(id, true);
    }
    // Otherwise disable if we have a banSelfClaim or if restrictedRules fail
    else if (
      (autoClaimRules && banSelfClaim) ||
      (restrictedRules &&
        restrictedRules.ids.filter((depId) => newEnabledBadgesMap.get(depId)).length < restrictedRules.count)
    ) {
      newEnabledBadgesMap.set(id, false);
    }

    if (!newEnabledBadgesMap.has(badge.id)) {
      newEnabledBadgesMap.set(badge.id, false);
    }
  }

  // Badge Baron is awarded automatically.
  if (submittedBadgesCount >= 10) {
    newEnabledBadgesMap.set('uwpa', true);
  } else {
    newEnabledBadgesMap.set('uwpa', false);
  }

  // Badger is awarded automatically.
  if (submittedBadgesCount >= 1) {
    newEnabledBadgesMap.set('65ry', true);
  } else {
    newEnabledBadgesMap.set('65ry', false);
  }

  // Pixel Prestige is awarded manually.
  if (pixel_prestige_winners.some((winner) => selectedProfile.email.includes(winner))) {
    newEnabledBadgesMap.set('20nu', true);
  } else {
    newEnabledBadgesMap.set('20nu', false);
  }

  // Hackathon Winner is awarded manually.
  if (hackathon_winners.some((winner) => selectedProfile.email.includes(winner))) {
    newEnabledBadgesMap.set('62ex', true);
  } else {
    newEnabledBadgesMap.set('62ex', false);
  }

  // Talos Badges QA Specialist is awarded manually.
  if (talos_badges_qa_specialists.some((winner) => selectedProfile.email.includes(winner))) {
    newEnabledBadgesMap.set('bpzs', true);
  } else {
    newEnabledBadgesMap.set('bpzs', false);
  }

  return newEnabledBadgesMap;
}
